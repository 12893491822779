import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import STYLES from '../styles/const';

const OutlinePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { title: { eq: "outline" } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);
  const { node } = data.allMarkdownRemark.edges[0];

  return (
    <Layout path="/outline">
      <SEO
        title="会社概要"
        description="株式会社アールエムシーの業務内容は、石炭採掘及び石炭の輸出入や販売仲介及びに運送のコンサルタント業務、砂利砂の採掘、輸出入業務、土地建設及び別荘地、募集権の販売及び、コンサルタント業務、古物、美術品、新車、中古車、トラック、重機車両全般の販売及びコンサルタント業務など、多岐に渡ります。"
      />
      <Wrapper>
        <Content dangerouslySetInnerHTML={{ __html: node.html }} />
        <Maps>
          
          <Image filename="map-naha-01.jpg" alt="那覇" />
          <Image filename="map-kakigara.jpg" alt="蛎殻町" />
        </Maps>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  /* padding-top: 40px; */
  padding: 40px 40px 0;
`;

const Content = styled.div`
  margin-right: 15px;
  font-size: 14px;
  dl {
    margin-bottom: 60px;
  }
  dt {
    float: left;
  }
  dd {
    margin-left: 100px;
    margin-bottom: 15px;
    line-height: 1.2;
  }
  .h5 {
    letter-spacing: 4px;
  }
  h5 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 4px;
  }
  ol {
    padding-left: 14px;
    list-style-type: decimal;
  }
  li {
    padding: 2px 0;
    border-bottom: solid 1px ${STYLES.COLOR.PRIMARY};
    line-height: 1.3;
  }
`;

const Maps = styled.div`
  width: 580px;
  div:not(:last-child) {
    margin-bottom: 5px;
  }


`;

export default OutlinePage;
